import React, {FC, useState} from 'react'
import {Button, Checkbox, Form, Input, Space} from 'antd'
import {Row} from 'antd/es'
import {usePageContext} from '../appContext'
import {CheckboxValueType} from 'antd/es/checkbox/Group'

export const priceRange: PriceRange = [[0, 50], [51, 100], [101, 150], [151, 200], [201, 250]]
export const genderList: Gender[] = ['female', 'male', 'unisex']
export const sizes = ['S', 'M', 'L', 'XL', 'XXL', 'XXXL']
export const colors = ['pink', 'pear', 'yellow', 'skyblue']

const PriceFilter: FC<{priceRange: PriceRange}> = ({priceRange}) => {
    const {data: {filterState: {price: value}}, handlers: {filterDispatch}} = usePageContext()
    const [formState, setFormState] = useState({
        min: Array.from(value)[0],
        max: Array.from(value)[1]
    })

    const handleChange = (checkedValues: CheckboxValueType[]) => {
        filterDispatch({
            type: 'SET_PRICE',
            payload: checkedValues
        })
    }

    const handleFormChange = ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => {
        setFormState({
            ...formState,
            [name]: Number(value) >= 0 ? parseInt(value) : 0
        })
    }

    return <Checkbox.Group onChange={handleChange} value={Array.from(value)}>
        <ul style={{listStyle: 'none', padding: 0}}>
            {priceRange.map((range,index) => <li style={{margin: '10px 0'}} key={index+1}>
                {/* [x,y].toString() => 'x,y'; toString is called for all html values */}
                <Checkbox
                    value={`[${range}]`}>
                    {`${range[0]}-${range[1]}`}
                </Checkbox>
            </li>)}
            <li key={'min-max-form'} style={{paddingRight: 5}}>
                <Form onFinish={(values) => {
                    const {min, max} = formState
                    const boundaries: PriceBoundary = {min: 0, max: 500}
                    const cleanData = (value: string, key: keyof PriceBoundary) => {
                        return (value && Number(value)) >= 0 ? parseInt(value) : boundaries[key as keyof PriceBoundary ]
                    }
                    handleChange([`[${cleanData(min as string, 'min')},${cleanData(max as string, 'max')}]`])
                }}>
                    <Space>
                        <Input
                            type='number'
                            value={formState.min as string}
                            name={'min'}
                            placeholder={'min'}
                            onChange={handleFormChange}
                            style={{maxWidth: 100}}
                        />
                        <Input
                            type={'number'}
                            value={formState.max as string}
                            placeholder={'max'}
                            name={'max'}
                            onChange={handleFormChange}
                            style={{maxWidth: 100}}
                        />
                        <Button htmlType={'submit'}>Apply</Button>
                    </Space>
                </Form>
            </li>
        </ul>
    </Checkbox.Group>
}

export const GenderFilter: FC<{genderList: Gender[]}> = ({genderList}) => {
    const {data: {filterState: {gender: value}}, handlers: {filterDispatch}} = usePageContext()

    const handleChange = (checkedValues: CheckboxValueType[]) => {
        filterDispatch({
            type: 'SET_GENDER',
            payload: checkedValues
        })
    }

    return <Checkbox.Group onChange={handleChange} value={Array.from(value)}> <ul style={{listStyle: 'none', padding: 0}}>
        {genderList.map((gender: Gender, index: number) => <li style={{margin: '10px 0'}} key={index+1}>
            <Checkbox value={gender}>
                {gender}
            </Checkbox>
        </li>)}
    </ul></Checkbox.Group>
}

export const BrandFilter: FC<{brandsList: string[]}> = ({brandsList}) => {
    const {data: {filterState: {brands: value}, activeGroupSlice }, handlers: {filterDispatch}} = usePageContext()

    const handleChange = (checkedValues: CheckboxValueType[]) => {
        filterDispatch({
            type: 'SET_BRAND',
            payload: checkedValues
        })
    }

    return <Checkbox.Group onChange={handleChange} value={Array.from(value)}> <ul style={{listStyle: 'none', padding: 0}}>
        {brandsList.map((brand: string, index: number) => <li style={{margin: '10px 0'}} key={index+1}>
            <Checkbox value={brand}>
                {brand}
            </Checkbox>
        </li>)}
    </ul></Checkbox.Group>
}

const UPARR = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMTAuNjY2MyAxLjVMNS45OTk2NyA2LjE2NjY3TDEuMzMzMDEgMS41IiBzdHJva2U9IiM3MDcwNzAiIHN0cm9rZS13aWR0aD0iMS42IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=='
const DOWNARR = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMS4zMzM3IDUuNUw2LjAwMDMzIDAuODMzMzNMMTAuNjY3IDUuNSIgc3Ryb2tlPSIjNzA3MDcwIiBzdHJva2Utd2lkdGg9IjEuNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo='

function withToggle<T>(Component: React.FC<T>) {
    return React.memo(function ({title, open = false, ...rest}: any) {
        const [show, setShow] = useState(open)

        return <div className={'has-overflow'}>
            <Row justify={'space-between'} style={{margin: '20px 0', cursor: 'pointer'}} onClick={() => setShow(!show)}>
                <h5 style={{fontSize: '14px'}}>{title}</h5>
                <img src={show ? UPARR : DOWNARR} alt="Show" />
            </Row>
            <div
                className={'lykdat-scrollbar overflow-y-auto'}
                style={{maxHeight: '250px', overflow: 'auto', display: show ? 'block' : 'none'}}>
                <Component {...rest} />
            </div>
        </div>
    })
}

export const PriceFilterWithToggle = withToggle<{priceRange: PriceRange}>(PriceFilter)
export const GenderFilterWithToggle = withToggle(GenderFilter)
export const BrandFilterWithToggle = withToggle(BrandFilter)

