import {usePageContext} from '../appContext'
import UploadBtn from './UploadBtn'
import React from 'react'

function PreviewPane(props: { src: string }) {
    const {handlers: {filterDispatch: dispatch, setResult, setUIMessage}} = usePageContext()

    return <div style={{position: 'relative'}} className={'PreviewPane'}>
        <img
            src={props.src}
            alt={''}
            className="query-image"
        />
        <div style={{position: 'absolute', left: 20, bottom: 20}}>
            <UploadBtn
                onSuccess={(data: SearchResult) => {
                    dispatch({type: 'CLEAR_FILTERS', payload: ''})
                    setResult(data)
                }}
                onError={(data: Error) => {
                    setUIMessage({
                        type: 'error',
                        message: data.message
                    })
                }}
                text={'Change Image'}
            />
        </div>
    </div>
}

export default PreviewPane
