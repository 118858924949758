import { useEffect } from 'react'
import ProductCard from '../components/ProductCard'
import { extractSimilarProductsCredentials } from '../helpers'
import useImageSearch from '../hooks/useImageSearch'

const SimilarProducts = () => {

    const credentials = extractSimilarProductsCredentials()

    const {
        data: [result, networkState ],
        handlers: {doSearch}
    } = useImageSearch({
        credentials: credentials ? {
            image_url: credentials.imageUrl,
            apiKey: credentials.apiKey,
            catalogName: credentials.catalogName
        } : null
    })

    const searchEffect = () => {
        if (!credentials) return
        doSearch()
    }

    useEffect(searchEffect, [])

    const hasResult = result?.result_groups?.[0].similar_products.length

    useEffect(() => {
        if(hasResult === undefined  || hasResult === 0) {
            window.parent.postMessage('lykdat--no--result', '*')
        }
    }, [hasResult])

    // render code
    if(networkState === 'loading') return <span>'Loading...'</span> 
    if(networkState === 'error') return null
 
    const similarProducts = result?.result_groups?.[0].similar_products.slice(0, 20)
    if (networkState === 'complete' && !similarProducts) return null

    similarProducts?.filter(product => product.id !== credentials?.productId)

    const cardsArr = similarProducts?.map((product: Product, index: number) =>
        <ProductCard {...product} key={`${product.id}--${index+1}`} />)

    return <section className={'SimilarProducts--wrapper'}>
            <h4 className={'SimilarProducts--title'}>Similar Products</h4>
            <div className={'SimilarProducts'}>
                { cardsArr }
            </div>
        </section>
}

export default SimilarProducts
