import {Button, Card} from 'antd'
import React from 'react'
import './ProductCard.css'

const isBargain = (product: Product) => product.reduced_price && +product.reduced_price > 0 && +product.reduced_price < +product.price

const ProductCard: React.FC<Product> = (product) => {
    const {currency, matching_image, category, brand_name, name, price, reduced_price, url} = product

    return <a href={url} target={'_blank'} className={'Product--link'}>
        <article className="Product"><img alt={name} src={matching_image} className={'Product--img'} loading={'lazy'}/>
            <div className={'Product--body'}><p className={'Product--name'}>{name}</p>
                <p className={'Product--price'}>
                    {
                        isBargain(product) ?
                            <>
                                <span className={'Product--old-price'}>{currency} {price}</span>
                                <span>{currency} {reduced_price}</span>
                            </>
                            : <span>{currency} {price}</span>
                    }

                </p>
                <Button block className={'Product--button'}>View Product</Button>
            </div>
        </article>
    </a>
}

export default ProductCard
