import axios, { AxiosPromise } from 'axios'

export function searchCatalogByImage(request: CatalogImageSearchRequest): AxiosPromise {
    const url = 'https://cloudapi.lykdat.com/v1/search'
    const data = new FormData()
    data.append('api_key', request.apiKey)
    data.append('catalog_name', request.catalogName)
    request.image_url
        ?
        data.append('image_url', request.image_url)
        :
        data.append('image', request.image ? request.image : '')

    const config = {
        method: 'post',
        url,
        data
    }

    return axios(config)
}

interface AlertSubscriptionRequest {
    apiKey: string;
    websiteName: string;
    productUrl: string;
    subscriberEmail: string;
    type: 'price' | 'availability';
}

export async function subscribeToAlert(request: AlertSubscriptionRequest): Promise<void> {
    const url = 'https://cloudapi.lykdat.com/v1/products/alerts/subscribe'
    const data = {
        product_url: request.productUrl,
        website_name: request.websiteName,
        subscriber_email: request.subscriberEmail,
        type: request.type
    }

    await axios.post(url, data, {headers: { 'x-api-key': request.apiKey }})
}
