import { Button, Modal } from 'antd/es'
import React, { useState } from 'react'
import PriceAlert from './forms/PriceAlert'

const App: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Get Price Alert
            </Button>
            <Modal
                footer={null}
                title={null}
                closable={false}
                style={{padding: 40}}
                onCancel={handleCancel}
                visible={isModalOpen}>
                <PriceAlert />
            </Modal>
        </>
    )
}

export default App
