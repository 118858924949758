import {useState} from 'react'
import {searchCatalogByImage} from '../helpers/apiClient'

interface Props {
    credentials: CatalogImageSearchRequest | null
    onError?: (arg: Error) => void
    onSuccess?: (arg: Product[]) => void
}
interface ReturnProps {
    data: [SearchResult | undefined, NetworkState],
    handlers: {
        doSearch: () => void
    }
}
function useImageSearch (props: Props): ReturnProps {
    const {credentials, onError, onSuccess} = props
    const [state, setState] = useState<NetworkState>('idle')
    const [data, setData] = useState<SearchResult>()

    const doSearch = () => {
        setState('loading')

        if (!credentials) {
            return
        }

        searchCatalogByImage(credentials).then(response => {
            setState('complete')
            setData(response.data.data)
            if(onSuccess) onSuccess(response.data.data)
        }).catch((err) => {
            setState('error')
            if(onError) onError(err)
        })
    }

    return {
        data: [data, state],
        handlers: {doSearch}
    }
}

export default useImageSearch
