export function extractLabels(slice: Product[]) {
    const obj = {}
    if(slice && slice.length) {
        slice.forEach((item: Product) => {
            if(item.brand_name){
                // @ts-ignore
                if(obj[item.brand_name]) {
                    // @ts-ignore
                    obj[item.brand_name].concat(item)
                } else {
                    // @ts-ignore
                    obj[item.brand_name]= [item]
                }
            }
        })
    }
    return obj
}

export const SORT_TYPES: SortField[] = [
    {
        label: 'Name: A-Z',
        key: 'name:asc',
    },
    {
        label: 'Name: Z-A',
        key: 'name:desc',
    },
    {
        label: 'Price: Low - High',
        key: 'price:asc',
    },
    {
        label: 'Price: High - Low',
        key: 'price:desc',
    },
    {
        label: 'Relevance',
        key: 'score:desc',
    },
]

export const getRange = (price: string[]) : PriceMinMax => {
    let [minPrice, maxPrice] = [Number.MAX_SAFE_INTEGER, 0]
    price.forEach((item: string) => {
        const itemArr: number[] = JSON.parse(item)
        if (itemArr[0] < minPrice) minPrice = itemArr[0]
        if (itemArr[1] > maxPrice) maxPrice = itemArr[1]
    })
    return [minPrice, maxPrice]
}

export const doPriceFilter = (products: Product[], priceRange: PriceMinMax) : Product[] => {
    const [minPrice, maxPrice] = priceRange
    return products.filter((item: Product) => +item.price >= +minPrice && +item.price <= +maxPrice)
}

export const doGenderFilter = (products: Product[], genderRange: Gender[]) => {
    return genderRange.flatMap((gender) => {
        return products.filter((product: Product) => {
            if(gender === 'unisex') {
                // Put unidentified products in the unisex list
                if(!product.gender || (product.gender.toLowerCase()) === 'unisex') return true
            }
            return product.gender && product.gender.toLowerCase() === gender
        })
    })
}

export const doBrandFilter = (products: Product[], brandRange: string[]) => {
    return brandRange.flatMap((brand: string) => products.filter((product: Product) => product.brand_name === brand))
}

export const doSort = (products: Product[], sortOrder: SortOrder) => {
    switch(sortOrder) {
    case 'name:desc':
        products.sort((a: Product, b: Product) => b.name.localeCompare(a.name))
        break
    case 'name:asc':
        products.sort((a: Product, b: Product) => a.name.localeCompare(b.name))
        break
    case 'price:desc':
        products.sort((a: Product, b: Product) => +b.price - +a.price)
        break
    case 'price:asc':
        products.sort((a: Product, b: Product) => +a.price - +b.price)
        break
    case 'score:desc':
        products.sort((a: Product, b: Product) => +b.score - +a.score)
        break
    default:

    }
    return products
}

/**
 * Extracts the user's API Key and catalog name from the base64 encoded query param.
 * The value encoded in base64 is a concatenation of multiple values like so:
 * <API Key> + <Catalog Name> + ":" + <Length of API key>
 */
export const extractImageSearchCredentials = (): {apiKey: string; catalogName: string} | null => {
    const creds = extractCredentials()
    if (!creds) {
        return null
    }

    const { apiKey, infix } = creds

    return { apiKey, catalogName: infix }
}

interface ProductAlertCredentials {
    apiKey: string
    websiteName: string
    productUrl: string
    formText?: string
    submissionProgressText?: string
    submissionSuccessText?: string
    submissionFailureText?: string
    ctaText?: string
    buttonColor?: string
    roundBorders?: boolean
}

/**
 * Extracts the user's API Key, website name and product url from the base64 encoded query param.
 * The value encoded in base64 is a concatenation of multiple values like so:
 * <API Key> + <website Name> + ":" + <Length of API key>
 */
export const extractAlertsCredentials = (): ProductAlertCredentials | null => {
    const urlParams = new URL(document.location.href).searchParams
    const productUrl = urlParams.get('url')
    if (!productUrl) {
        return null
    }

    const creds = extractCredentials()
    if (!creds) {
        return null
    }

    const { apiKey, infix } = creds

    const formText = urlParams.get('formText') || undefined
    const submissionProgressText = urlParams.get('submissionProgressText') || undefined
    const submissionSuccessText = urlParams.get('submissionSuccessText') || undefined
    const submissionFailureText = urlParams.get('submissionFailureText') || undefined
    const ctaText = urlParams.get('ctaText') || undefined
    const buttonColor = urlParams.get('buttonColor')?.toLocaleLowerCase()?.trim()
    const roundBorders = urlParams.get('roundBorders') === 'true'

    return {
        apiKey,
        productUrl,
        formText,
        submissionProgressText,
        submissionSuccessText,
        submissionFailureText,
        ctaText,
        roundBorders,
        buttonColor: isValidColor(buttonColor) ? buttonColor : undefined,
        websiteName: infix,
    }
}

const isValidColor = (color?: string) => {
    const unwantedColors = ['inherit', 'transparent', 'initial', 'currentcolor', 'none', 'unset']
    if (!color || unwantedColors.includes(color)) {
        return false
    }

    const s = new Option().style
    s.color = color
    return s.color !== ''
}

/**
 * Extracts the user's API Key and other params infixed from the base64 encoded query param.
 * The value encoded in base64 is a concatenation of multiple values like so:
 * <API Key> + <Infixed value> + ":" + <Length of API key>
 */
const extractCredentials = (): {apiKey: string; infix: string} | null => {
    const urlParams = new URL(document.location.href).searchParams
    const context = urlParams.get('context')
    if (!context) {
        return null
    }

    const decoded = window.atob(context)
    const maybeLength = decoded.split(':').pop()
    if (!maybeLength) {
        return null
    }

    const apikeyLength = parseInt(maybeLength, 10)
    const apiKey = decoded.substring(0, apikeyLength)
    // substract length of number string (e.g "32") along with ":"
    const infixEndsAt = decoded.length - maybeLength.length - 1
    const infix = decoded.substring(apikeyLength, infixEndsAt)

    return { apiKey, infix }
}

/**
 * Extracts the user's API Key, website name and product url from the base64 encoded query param.
 * The value encoded in base64 is a concatenation of multiple values like so:
 * <API Key> + <website Name> + ":" + <Length of API key>
 */
export const extractSimilarProductsCredentials = (): SimilarProductsCredentials | null => {
    const urlParams = new URL(document.location.href).searchParams
    const productId = urlParams.get('productId')
    const imageUrl = urlParams.get('imageUrl')

    if (!productId || !imageUrl) {
        return null
    }

    const creds = extractCredentials()
    if (!creds) {
        return null
    }

    const { apiKey, infix } = creds

    return { apiKey, infix, catalogName: infix, productId, imageUrl }
}
