import React from 'react'
import styled from 'styled-components'
import theme from '../theming'
import {Scale} from '../theming/Base'

export interface Props {
    className?: string
    size: Scale
    src: string
    round?: boolean
}

const Thumbnail: React.FC<Props> = (props) => {
    const { className } = props

    return <div className={className} />
}

const StyledThumbnail = styled(Thumbnail)((props) => {
    return {
        height: theme.size[props.size],
        width: theme.size[props.size],
        background: `url('${props.src}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: props.round ? '50%' : '8px',
    }
})
export default StyledThumbnail
