import { Drawer } from 'antd'
import React, { useState } from 'react'
import './ProductDrawer.css'
import SearchResult from './SearchResult'
import UploadBtn from './UploadBtn'
import Message from './Message'
import classnames from 'classnames'

const ProductDrawer = () => {
    const [data, setData] = useState<SearchResult | null>(null)
    const [errorMessage, setErrorMessage] = useState('')

    const onClose = () => {
        setData(null)
        window.parent.postMessage('close--lykdat--drawer', '*')
    }

    return (
        <>
            <Drawer
                title={'Image Search'}
                placement="right"
                onClose={onClose}
                width={'inherit'}
                visible={true}
                className={classnames(['Drawer', {
                    full: data
                }])}
                bodyStyle={{ padding: '0 10px' }}
                headerStyle={{ border: 'none' }}
            >
                {data ? (
                    <SearchResult data={data} />
                ) : (
                    <div
                        style={{
                            width: '100%',
                            padding: 40,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                minHeight: '162px',
                                flexDirection: 'column',
                                padding: '10px',
                                background: '#EAF2FD',
                                display: 'flex',
                                border: '2px dashed #2F80ED',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <p className="ant-upload-text" style={{ fontSize: '16px' }}>
                Upload an image to search
                            </p>
                            <Message
                                content={errorMessage}
                                type={'error'}
                                onClose={() => setErrorMessage('')}
                            />
                            <UploadBtn
                                onSuccess={(data) => {
                                    if(data) setData(data)
                                }}
                                onError={(error: Error) => setErrorMessage(error.message || '')}
                                text={'Upload an Image'}
                            />
                        </div>
                    </div>
                )}
            </Drawer>
        </>
    )
}

export default ProductDrawer
