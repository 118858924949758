import React, {FormEventHandler, useState} from 'react'
import {Button, Row} from 'antd/es'
import {Input} from 'antd'

const PriceAlert = () => {
    const [email, setEmail] = useState<string>('')
    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        console.log(email)
        setEmail('')
        e.preventDefault()
    }

    return <form onSubmit={handleSubmit}>
        <p style={{fontSize: 16, margin: 0}}>
            Enter Your Email to get Notified When There’s a Change in Price for this Product
        </p>
        <Row style={{padding: '20px 0'}}>
            <Input
                placeholder={'email@example.com'}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                name={'email'} required type={'email'}
            /></Row>
        <Row>
            <Button
                type="primary"
                htmlType={'submit'}
                disabled={!email}
            >
                Get Notified
            </Button>
        </Row>
    </form>
}

export default PriceAlert
