import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/global.min.css'
import './styles/index.scss'
import App from './App'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('lykdat-storefront-root') as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)
