import {createContext, useContext} from 'react'

export const PageContext = createContext<{ data: any; handlers?: any }>({
    data: {},
    handlers: {},
})

export function usePageContext() {
    return useContext(PageContext)
}

export function toggleSet(set: Set<string>, s: string) {
    if (set.has(s)) {
        set.delete(s)
    } else {
        set.add(s)
    }
    return set
}

export interface FilterState {
    price: Set<string>,
    gender: Set<string>,
    brands: Set<string>,
    sizes: Set<string>,
    colors: Set<string>
}
const initState: FilterState = {
    price: new Set<string>(),
    gender: new Set<string>(),
    brands: new Set<string>(),
    sizes: new Set<string>(),
    colors: new Set<string>()
}
export const reducer = (state: FilterState, action: {type: string, payload: string}) => {
    function filter(state: FilterState, key: keyof FilterState ){
        // const slice = state[key];
        // debugger;
        return {
            ...state,
            [key]: new Set(action.payload)
        }
    }
    switch (action.type) {
    case 'CLEAR_FILTERS':
        return initState
    case 'SET_GENDER':
        return filter(state, 'gender')
    case 'SET_SIZE':
        return filter(state, 'sizes')
    case 'SET_COLOR':
        return filter(state, 'colors')
    case 'SET_BRAND':
        return filter(state, 'brands')
    case 'SET_PRICE':
        return filter(state, 'price')
    default:
        return state
    }

}
