import React, {useEffect, useState} from 'react'
import classnames from 'classnames'
import './Message.css'

interface Props {
    content: string;
    type: 'error' | 'warning';
    onClose?: () => void;
}

const Message = (props: Props) => {
    const [show, setShow] = useState(true)

    useEffect(() => {
        setShow(props.content.length > 0)
    }, [props.content])

    return <div className={classnames('Message', props.type, {'show': show })}>
        <button className={'close'} onClick={(e) => {
            setShow(!show)
            if(typeof props.onClose === 'function')
                props.onClose()
        }}>x</button>
        {props.content}
    </div>
}

export default Message
