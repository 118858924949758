import React, { FormEventHandler, useState } from 'react'
import {Col, Input} from 'antd'
import {Button, Row} from 'antd/es'
import { extractAlertsCredentials } from '../../helpers'
import * as apiClient from '../../helpers/apiClient'

const subscribeToAlert = async (subscriberEmail: string) => {
    const credentials = extractAlertsCredentials()
    if (!credentials) {
        console.log('aborted, no credentials found')
        return
    }

    await apiClient.subscribeToAlert({
        subscriberEmail,
        type: 'availability',
        ...credentials
    })
}

const STATIC_MESSAGE = 'Enter Your Email and get Notified When this Product is back in stock'
const LOADING_MESSAGE = 'Submitting...'
const SUCCESS_MESSAGE = 'Saved! You\'ll receive an email once it\'s back in stock.'
const FAILED_MESSAGE = 'Something went wrong. Please try again.'

const StockAlert = () => {
    const credentials = extractAlertsCredentials()
    const [message, setMessage] = useState(credentials?.formText || STATIC_MESSAGE)
    const [emailAddress, setEmail] = useState<string>('')
    const ctaText = credentials?.ctaText
    const buttonColor = credentials?.buttonColor
    const roundBorders = credentials?.roundBorders
    const loadingMessage = credentials?.submissionProgressText || LOADING_MESSAGE
    const successMessage = credentials?.submissionSuccessText || SUCCESS_MESSAGE
    const failedMessage = credentials?.submissionFailureText || FAILED_MESSAGE

    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()

        if (emailAddress) {
            setMessage(loadingMessage)
            subscribeToAlert(emailAddress.toString())
                .then(() => {
                    setMessage(successMessage)
                    setEmail('')
                }).catch((e) => {
                    console.log(e)
                    setMessage(failedMessage)
                })
        }
    }

    const buttonStyle = {
        ...(roundBorders ? { borderRadius: 15 } : {}),
        ...(buttonColor ? { backgroundColor: buttonColor, borderColor: buttonColor } : {})
    }

    const inputStyle = {
        ...(roundBorders ? { borderRadius: 15 } : {})
    }

    return (
        <form onSubmit={handleSubmit} style={{padding: 20, maxWidth:510}}>
            <p style={{fontWeight: 500}}>
                {message}
            </p>
            <Row gutter={[16, 16]}>
                <Col flex={'auto'}>
                    <Input
                        style={inputStyle}
                        placeholder={'email@example.com'}
                        value={emailAddress}
                        onChange={(event) => setEmail(event.target.value)}
                        name={'email'} required type={'email'}/>
                </Col>
                <Col xs={'12'} md={'6'}>
                    <Button
                        type="primary"
                        htmlType={'submit'}
                        disabled={!emailAddress}
                        style={buttonStyle}
                    >
                            {ctaText || 'Get Notified'}
                    </Button>
                </Col>
            </Row>
        </form>
    )
}

export default StockAlert
