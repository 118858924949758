import React from 'react'
import ProductDrawer from './components/ProductDrawer'
import {Route, Routes} from 'react-router-dom'
import StockAlert from './components/forms/StockAlert'
import PriceAlert from './components/PriceAlert'
import SimilarProducts from './pages/SimilarProducts'

function App() {
    return <Routes>
        <Route
            element={<ProductDrawer/>}
            path="/"
            // errorElement={<ErrorBoundary />}
        />
        <Route
            element={<StockAlert />}
            path="/stock-alert"
        // errorElement={<ErrorBoundary />}
        />
        <Route
            element={<PriceAlert />}
            path="/price-alert"
        />
        <Route
            element={<SimilarProducts />}
            path="/similar-products"
        />
    </Routes>
}

export default App
