import {usePageContext} from '../appContext'
import React, {forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Row, Space} from 'antd/es'
import {Button, Dropdown, Menu} from 'antd/es'
import ProductCard from './ProductCard'
import {doBrandFilter, doGenderFilter, doPriceFilter, doSort, getRange, SORT_TYPES} from '../helpers'

const ActiveSlice: ForwardRefRenderFunction<any, {data: ResultGroup}> = ({data: activeSlice }, ref: any) => {

    const {data: {sortOrder, filterState: {price, gender, brands}}, handlers: {setSortOrder}} = usePageContext()
    const activeSort = (SORT_TYPES.find(item => item.key === sortOrder))
    const sortLabel = activeSort ? activeSort.label : 'Default Sort'

    const [filteredProducts, setFilteredProducts] = useState<Product[]>( [])

    useEffect(() => {
        let result = activeSlice.similar_products
        if(price.size) {
            const priceRange = getRange(price)
            result = doPriceFilter(result, priceRange)
        }
        if(gender.size) {
            result = doGenderFilter(result, Array.from(gender))
        }
        if(brands.size) {
            result = doBrandFilter(result, Array.from(brands))
        }
        setFilteredProducts(result)
    }, [activeSlice, price, gender, brands])

    const products = (price.size || gender.size || brands.size) ?
        filteredProducts :
        activeSlice.similar_products
    const sortedProducts = doSort(products, sortOrder)

    const divRef = useRef<HTMLDivElement>()
    useImperativeHandle(ref, () => ({
        doScrollToTop: () => {
            if(divRef && divRef.current) {
                divRef.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                })
            }
        }
    }))

    // @ts-ignore
    return <div ref={divRef}
        className={'lykdat-scrollbar overflow-y-auto ResultWrapper'}
    >
        <Row justify={'space-between'} className={'ResultHeader'}>
            <h3 style={{margin: 0}}>Result ({products.length})</h3>
            <p style={{margin: 0}}>Sort by: <Dropdown overlay={<Menu items={SORT_TYPES} onClick={(e) => setSortOrder(e.key)}/>}>
                <Button>
                    <Space>
                        {sortLabel}
                    </Space>
                </Button>
            </Dropdown></p>
        </Row>
        <div className={'Products'} >
            { sortedProducts.map((product: Product, index: number) =>
                <ProductCard {...product} key={`${product.id}--${index+1}`}
                />)}
        </div>
    </div>
}

export default forwardRef(ActiveSlice)
