import React, {useReducer, useMemo, useState, useRef, useEffect} from 'react'
import {Button, Layout } from 'antd'
import {Drawer, Row} from 'antd/es'
import {
    BrandFilterWithToggle,
    GenderFilterWithToggle, genderList,
    PriceFilterWithToggle, priceRange,
} from './Filters'
import {FilterState, PageContext, reducer} from '../appContext'
import ActiveSlice from './ActiveSlice'
import ToggleBar from './ToggleBar'
import {extractLabels} from '../helpers'
import PreviewPane from './PreviewPane'
import Message from './Message'
import './SearchResult.css'
import classnames from 'classnames'

const filterIcon = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xLjI1IDIuMjVDMS4yNSAxLjY5NzcyIDEuNjk3NzIgMS4yNSAyLjI1IDEuMjVIMTMuNzVDMTQuMzAyMyAxLjI1IDE0Ljc1IDEuNjk3NzIgMTQuNzUgMi4yNVYzLjgzNTc5QzE0Ljc1IDQuMTAxIDE0LjY0NDYgNC4zNTUzNiAxNC40NTcxIDQuNTQyODlMOS43OTI4OSA5LjIwNzExQzkuNjA1MzYgOS4zOTQ2NCA5LjUgOS42NDkgOS41IDkuOTE0MjFWMTEuNzVMNi41IDE0Ljc1VjkuOTE0MjFDNi41IDkuNjQ5IDYuMzk0NjQgOS4zOTQ2NCA2LjIwNzExIDkuMjA3MTFMMS41NDI4OSA0LjU0Mjg5QzEuMzU1MzYgNC4zNTUzNiAxLjI1IDQuMTAxIDEuMjUgMy44MzU3OVYyLjI1WiIgc3Ryb2tlPSIjMDgwODA4IiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo='

const initState: FilterState = {
    price: new Set<string>(),
    gender: new Set<string>(),
    brands: new Set<string>(),
    sizes: new Set<string>(),
    colors: new Set<string>()
}

interface Props {
    data: SearchResult
}

const sumReducer = (acc: number, curr: Set<unknown>) => curr.size+acc

const MobileFilterDrawer: React.FC<{render: React.ReactNode, count: number }> = ({render, count}) => {
    const [open, setOpen] = useState(false)

    const showDrawer = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    return <>
        <div className="mobile-only">
            <Button onClick={showDrawer}>
                <img src={filterIcon} style={{padding: 4}}/>
                Filter Options ({count})
            </Button>
        </div>
        <Drawer
            title={'Filter Options'}
            placement="right"
            onClose={onClose}
            width={'90%'}
            visible={open}
            // className={classnames(['Drawer'])}
            bodyStyle={{ padding: '0 16px' }}
            headerStyle={{ border: 'none' }}
        >
            {render}
        </Drawer>
    </>
}

const SearchResult = (props: Props) => {
    const [result, setResult] = useState(props.data)
    const [uIMessage, setUIMessage] = useState<{type: 'error' | 'warning', message: string}>({
        type: 'error',
        message: ''
    })
    const [filterState, dispatch] = useReducer<typeof reducer, FilterState>(reducer, initState, (initState) => initState)
    const [sortOrder, setSortOrder] = useState('')

    const [activeGroup, setActiveGroup] = useState('')
    const {result_groups: groups, query_image: queryImage} = result
    const activeSliceRef = useRef<HTMLDivElement>()

    useEffect(() => {
        setActiveGroup(groups[0].detected_item.name)
    }, [result])

    useEffect(() => {
        // This imperative method is exposed from the
        // ActiveSlice Child Component through useImperativeHandle
        !!(activeSliceRef.current) // @ts-ignore
            && activeSliceRef.current.doScrollToTop()
    }, [filterState, sortOrder, activeGroup])

    const activeGroupSlice = groups ? groups.find((item: any) => item.detected_item.name === activeGroup) : null

    const filterSize = Object.values(filterState).reduce(sumReducer, 0)
    const brandsMap = useMemo(() => activeGroupSlice ? extractLabels(activeGroupSlice.similar_products) : {}, [activeGroupSlice])
    const brandsList = Object.keys(brandsMap)

    const context = useMemo(
        () => ({
            data: { filterState, sortOrder, activeGroupSlice, uIMessage},
            handlers: { filterDispatch: dispatch, setSortOrder, setResult, setUIMessage },
        }),
        [filterState, dispatch, sortOrder, activeGroupSlice, result]
    )

    return <PageContext.Provider value={context}>
        <Layout>
            <Layout.Sider
                width={340}
                className={'Sidebar lykdat-scrollbar overflow-y-auto'}
            >

                <Row>
                    <PreviewPane src={queryImage} />

                    <div className={'Detected--items'}>
                        {groups && <ToggleBar
                            items={groups}
                            onSwitch={setActiveGroup}
                            queryImage={queryImage}
                        />}
                    </div>
                </Row>

                <section style={{padding: '40px 0'}}>
                    <Row justify={'space-between'}>
                        <h5 style={{fontSize: '14px'}}>Filter Options</h5>
                        <Button
                            onClick={() => dispatch({type: 'CLEAR_FILTERS', payload: ''})}>
                            Clear ({filterSize})
                        </Button>
                    </Row>
                    <PriceFilterWithToggle title={'Price Range'} priceRange={priceRange} open/>
                    {/* @todo: To be made configurable from the query params sent along with the store context */}
                    {/*<GenderFilterWithToggle title={"Gender"} genderList={genderList} />*/}
                </section>
            </Layout.Sider>
            <Layout.Content className={'has-overflow'}>
                {uIMessage.message && <Message content={uIMessage.message} type={uIMessage.type} onClose={() => setUIMessage({type: 'error', message: ''})}/> }
                <section className={'Mobile-preview'}>
                    <PreviewPane src={queryImage} />
                    <div style={{margin: '10px 0'}}>
                        {groups && <ToggleBar
                            items={groups}
                            onSwitch={setActiveGroup}
                            queryImage={queryImage}
                        />}
                    </div>
                </section>
                {activeGroupSlice && <>
                    <MobileFilterDrawer count={filterSize} render={<>
                        <Row justify={'space-between'}>
                            <h5 style={{fontSize: '14px'}}>Filter Options</h5>
                            <Button
                                onClick={() => dispatch({type: 'CLEAR_FILTERS', payload: ''})}>
                                Clear ({filterSize})
                            </Button>
                        </Row>
                        <PriceFilterWithToggle title={'Price Range'} priceRange={priceRange} open/>
                    </>}/>
                    <ActiveSlice data={activeGroupSlice} ref={activeSliceRef}/></>}
            </Layout.Content>
        </Layout>
    </PageContext.Provider>
}

export default SearchResult
