import React, {useState} from 'react'
import CutOut from './CutOut'
import styled from 'styled-components'
import theme from '../theming'

export interface Props {
    className?: string
    items: any[]
    queryImage: string
    onSwitch?: (arg: string) => void
}

const ToggleBar: React.FC<Props> = (props) => {
    const { className, onSwitch } = props
    const heading = props.items.length > 1 ? 'Multiple items detected' : 'Single item detected'
    const [current, setCurrent] = useState(0)

    return (
        <div className={className}>
            <h4 className={'heading'}>{heading}</h4>
            <div className="wrapper">
                {props.items.map((item, index) => (
                    <div
                        className="locItem"
                        key={index}
                        onClick={() => {
                            setCurrent(index)
                            if(onSwitch) onSwitch(item.detected_item.name)
                        }}
                    >
                        <CutOut
                            imageSrc={props.queryImage}
                            item={item.detected_item}
                            highlight={index === current}
                        />
                        <span className="bubbleCaption">{item.detected_item.name || 'Item'}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

const StyledToggleBar = styled(ToggleBar)((props) => {
    return {
        padding: '20px 0',
        [`${theme.media.smallScreenBreakpoint}`]: {
            padding: '0 20px',
        },
        ['.wrapper']: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10
        },
        ['.locItem']: {
            cursor: 'pointer',
            borderRadius: '6px',
            [':first-of-type']: {
                paddingLeft: 0
            },
            width: '76px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                width: '52px',
            },
        },
        ['.heading']: {
            display: 'inline-grid',
            textTransform: 'capitalize',
            [`${theme.media.smallScreenBreakpoint}`]: {
                display: 'block',
                fontSize: 12,
                whiteSpace: 'nowrap',
            },
        },
        '.bubbleCaption': {
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'left',
            margin: '4px 5px 0 5px',
            textTransform: 'capitalize',
            width: '76px',
            [`${theme.media.smallScreenBreakpoint}`]: {
                width: '52px',
            },
        },
    }
})
export default StyledToggleBar
