import React, { ChangeEvent, useState } from 'react'
import {Button} from 'antd'
import {searchCatalogByImage} from '../helpers/apiClient'
import Loading from './Loading'
import { extractImageSearchCredentials } from '../helpers'

interface Props {
    text: string;
    onSuccess?: (data: SearchResult) => void;
    onError?: (data: Error) => void;
}

const UploadBtn = (props: Props) => {
    const {onSuccess, onError, text} = props
    const [state, setState] = useState<'loading' | 'complete' | 'error' | 'idle'>('idle')

    const handleUpload = (e: ChangeEvent) => {
        setState('loading')
        let credentials
        try {
            setState('loading')
            credentials = extractImageSearchCredentials()
        } catch (e) {
            setState('error')
            if(onError) {
                onError(new Error('Your access key appears to be invalid. Please try again.'))
            }
        }

        if (!credentials) {
            return
        }

        searchCatalogByImage({
            catalogName: credentials.catalogName,
            apiKey: credentials.apiKey,
            // @ts-ignore
            image: e.target ? e.target.files[0] : null,
        }).then(response => {
            setState('complete')
            if(onSuccess) onSuccess(response.data.data)
        }).catch((err) => {
            setState('error')
            if(onError) onError(err)
        })
    }

    return state === 'loading' ? <Loading /> : <Button type={'primary'} style={{overflow: 'hidden', background: 'black', color: 'white', border: 0, borderRadius: 6}}>
        <span>{text ? text : 'Upload'}</span>
        <input
            type="file"
            style={{
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                position: 'absolute',
                left: 0,
                bottom: 0,
                padding: 100,
            }}
            title={''}
            accept="image/*"
            onChange={handleUpload}
        />
    </Button>
}

export default UploadBtn
